import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import useFilterValues from './FilterValues/useFilterValues';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';
import { FiltersEntity, FOREIGN_PROCEEDINGS_ADMIN_SLUG_FILTER } from '../../../../Layout/model/Filters';
import useSaveFilter from '../../../../Shared/helper/hooks/useSaveFilter';
import { createSearchingProps } from '../../../../Shared/helper/params';
import ForeignProceedings from '../../../model/ForeignProceedings';
import SaveFilterModal from '../../../../Layout/component/ListFilter/SaveFilterModal';

export interface Props {
  isAdmin?: boolean;
}

const ForeignProceedingsFilter: React.FC<Props> = ({ isAdmin = false }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setForeignProceedingsListParamsAction(payload, isAdmin));
  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    setParams({ filter: saveFilter });
  };
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick: onFilterSelectedClickFn,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, FOREIGN_PROCEEDINGS_ADMIN_SLUG_FILTER, ['keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  useEffect(() => {
    if (Object.keys(filter || {}).length && !_.isEqual(filter, filterParams)) {
      setFilterParams({ ...filter });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchButtonClicked = () =>
    setParams({
      filter: filterParams
    });

  const onFilterSelectedClick = (id: number) => {
    setFilterParams(filter);
    setParams({ filter });
    onFilterSelectedClickFn(id);
  };

  const filteredFilterList = filtersList.filter((el) => !el.filters.only_saved);

  const searchProps = createSearchingProps(
    '',
    ForeignProceedings.getFilterableAttributes(),
    ForeignProceedings.getSortableAttributes()
  );

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filteredFilterList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsforeign_proceedings');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const { inputs } = useFilterValues({ values: filterParams, isAdmin });

  let disableBtn = false;

  const { ...restFilter } = filter;
  if (_.isEmpty(restFilter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      <ListingFilter
        inputs={inputs}
        filter={filterParams}
        filtersName="foreign_proceedings"
        pagination={pagination}
        setParams={(params) => setFilterParams(params.filter)}
        filterName="Wyszukiwanie"
        disableBtn={disableBtn}
        saveFilterBtn={() => setMessage(true)}
        filterObj={{
          filtersList: filteredFilterList,
          onDeleteFilterClick,
          onFilterSelectedClick: onFilterSelectedClickFn,
          loading
        }}
        searchButtonObj={{ onClick: onSearchButtonClicked }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default ForeignProceedingsFilter;
