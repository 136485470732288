export type ExecutorStatus = {
  name: string;
  status: number;
};

export type ExecutorEntity = {
  id?: number;
  name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  nip?: string;
  status?: ExecutorStatus;
  foreign_nip?: boolean;
  city?: string;
  voivodeship?: string;
  street?: string;
  postal_code?: string;
  post_office?: string;
  phone?: string;
  legal_form?: string;
  penalties_total_value?: number;
  business_start_date?: string;
  krs?: string;
  is_deletable?: boolean;
  updated_at?: string;
  created_at?: string;
};

export default class Executor {
  readonly id?: number;
  readonly name?: string;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly nip?: string;
  readonly status?: ExecutorStatus;
  readonly foreign_nip?: boolean;
  readonly krs?: string;
  readonly city?: string;
  readonly street?: string;
  readonly postal_code?: string;
  readonly post_office?: string;
  readonly phone?: string;
  readonly legal_form?: string;
  readonly penalties_total_value?: number;
  readonly business_start_date?: string;
  readonly voivodeship?: string;
  readonly is_deletable?: boolean;
  readonly updated_at?: string;
  readonly created_at?: string;

  constructor(entity: ExecutorEntity) {
    this.id = entity.id;
    this.first_name = entity.first_name;
    this.name = entity.name;
    this.last_name = entity.last_name;
    this.email = entity.email;
    this.nip = entity.nip;
    this.status = entity.status;
    this.foreign_nip = entity.foreign_nip;
    this.krs = entity.krs;
    this.city = entity.city;
    this.street = entity.street;
    this.postal_code = entity.postal_code;
    this.post_office = entity.post_office;
    this.phone = entity.phone;
    this.legal_form = entity.legal_form;
    this.penalties_total_value = entity.penalties_total_value;
    this.voivodeship = entity.voivodeship;
    this.updated_at = entity.updated_at;
    this.business_start_date = entity.business_start_date;
    this.is_deletable = entity.is_deletable;
    this.created_at = entity.created_at;
  }

  static getAdminFilterableAttributes(): string[] {
    return [
      'name',
      'first_name',
      'last_name',
      'street',
      'status',
      'postal_code',
      'city',
      'post_office',
      'nip',
      'has_problems'
    ];
  }

  static getFilterableAttributes(): string[] {
    return [...this.getAdminFilterableAttributes(), 'penalties_total_value_min', 'penalties_total_value_max'];
  }

  static getAdminSortableAttributes(): string[] {
    return ['name', 'first_name', 'last_name', 'street', 'status', 'postal_code', 'city', 'post_office', 'nip'];
  }

  static getSortableAttributes(): string[] {
    return [...this.getAdminSortableAttributes(), 'penalties_total_value'];
  }
}

export const createExecutor = (entity: ExecutorEntity): Executor => new Executor(entity);

export const executorHasProblemsOptions = () => {
  return [
    { value: 1, label: 'Tak' },
    { value: 0, label: 'Nie' }
  ];
};
