import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import ForeignProceedings, { foreignProceedingsStatus } from '../../../model/ForeignProceedings';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  element?: ForeignProceedings;
};

const ForeignProceedingsBasicDataset: React.FC<Props> = ({ element }) => {
  const fieldsPartOne = [
    {
      label: 'Status postępowania',
      value: foreignProceedingsStatus(Number(element?.status)) || 'brak danych'
    },
    {
      label: 'Nazwa postępowania',
      value: element?.name || 'brak danych'
    },
    {
      label: 'Link do oryginału',
      value:
        (
          <a rel="noreferrer" target="_blank" href={element?.href}>
            {element?.href}
          </a>
        ) || 'brak danych'
    },
    {
      label: 'Data publikacji',
      value: ifContainsDateFormat(element?.published_date, formatDate) || 'brak danych'
    },
    {
      label: 'Termin składania ofert',
      value: ifContainsDateFormat(element?.deadline_date, formatDatetimeSeconds) || 'brak danych'
    },
    {
      label: 'Kraj wykonywania zlecenia',
      value:
        element?.countries.length > 0
          ? element?.countries.map((country) => `${country.country_name}${element?.countries.length > 1 ? ', ' : ''}`)
          : 'brak danych'
    },
    {
      label: 'Portal źródłowy',
      value: element?.source_name || 'brak danych'
    },
    {
      label: 'Procedura',
      value: element?.procedure || 'brak danych'
    }
  ];

  const fieldsDependingOnForeignProceedingType = [
    {
      label: 'Numer referencyjny',
      value: element?.ref_nr || 'brak danych'
    }
  ];

  const fieldsPartTwo = [
    {
      label: 'Zamawiający',
      value: element?.ordered_by || 'brak danych'
    },
    {
      label: 'Tryb dla platformy',
      value: element?.platform_mode || 'brak danych'
    }
  ];

  const fields = [...fieldsPartOne, ...fieldsPartTwo, ...fieldsDependingOnForeignProceedingType];

  return <InformationList fields={fields} />;
};

export default ForeignProceedingsBasicDataset;
