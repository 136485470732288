import {
  DATETIME_FORMAT_VALUE,
  DATETIME_SECONDS_FORMAT,
  findMonthName,
  formatDateIfExist,
  formatDatetimeSeconds,
  getCurrentDateInFormat
} from 'modules/Shared/helper/utils';
import moment from 'moment';
import {
  AGREEMENT_MODE_CONSTRUCTION_WORKS,
  AGREEMENT_MODE_DELIVERIES,
  AGREEMENT_MODE_SERVICES,
  AgreementMode
} from 'modules/Agreements/type';
import {
  FOREIGN_PROCEEDINGS_FILES_TYPES,
  FOREIGN_PROCEEDINGS_FILES_VALUES,
  FOREIGN_PROCEEDINGS_INIT_TYPES,
  FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES,
  FOREIGN_PROCEEDINGS_QUESTIONS_DATE_MESSAGES,
  FOREIGN_PROCEEDINGS_STATUS,
  FOREIGN_PROCEEDINGS_STATUS_VALUES,
  FOREIGN_PROCEEDINGS_TYPES,
  FOREIGN_PROCEEDINGS_TYPES_VALUES,
  FOREIGN_PROCEEDINGS_VERIFICATION_LABELS,
  FOREIGN_PROCEEDINGS_VERIFICATION_VALUES
} from '../type';
import { START_DATE_TYPES, START_DATE_TYPES_VALUES } from '../../Proceedings/type';

export type ForeignProceedingsFiles = {
  id?: number;
  type?: number;
  name?: string;
  href?: string;
  created_at?: string;
  updated_at?: string;
};

export type ForeignProceedingsLink = {
  id?: number | string;
  link?: string;
  name?: string;
};

export type ForeignProceedingsCodes = {
  id?: number;
  code: string;
  type: string;
};

export type ForeignProceedingIndustry = {
  id: number;
  industry_name: string;
  industry_type_id?: number;
  parent_type_name?: string;
};

export type ForeignProceedingDescriptionAdditional = {
  label: string;
  value: string | number;
  translation: string;
};

export type ForeignProceedingsEntity = {
  id?: number;
  proceeding_type?: number;
  status?: number | string;
  name?: string;
  country_code?: string;
  code?: string;
  countries?: { code: string; country_name: string }[];
  identifier?: string;
  description_additional?: ForeignProceedingDescriptionAdditional[];
  bzp_identifier?: string;
  bzp_date?: string;
  contractor_name?: string;
  contractor_address?: string;
  contractor_email?: string;
  contractor_nip?: string;
  contractor_regon?: string;
  value?: number;
  links?: ForeignProceedingsLink[];
  requires_deposit?: boolean;
  deposit?: number;
  type?: number;
  mode?: number;
  social_clauses?: string;
  order_object?: string;
  email_contact_checked?: boolean;
  epd2_contact_checked?: boolean;
  post_contact_checked?: boolean;
  other_contact_checked?: boolean;
  other_contact_content?: string;
  announcement_link?: string;
  start_date_type?: number;
  start_date?: string | number;
  offers_send_date?: string;
  answer_place?: string;
  answer_way?: string;
  verification?: number;
  shopping_platform?: string;
  keywords_names?: string;
  keywords?: { id: number; name: string }[];
  files?: ForeignProceedingsFiles[];
  parts?: number | null;
  is_saved?: boolean;
  questions_date?: string;
  cpv_codes?: string[];
  codes?: ForeignProceedingsCodes[];
  cpv_codes_string?: string;
  from_bzp?: boolean;
  industry_type_id?: number;
  industry_sub_type_id?: number;
  industries?: ForeignProceedingIndustry[];
  created_at?: string;
  updated_at?: string;
  order_nr?: string;
  slug_source?: string;
  source_name?: string;
  published_date?: string;
  deadline_date?: string;
  ordered_by?: string;
  ordered_by_info?: string;
  ref_nr?: string;
  href?: string;
  description?: string;
  procedure?: string;
  platform_mode?: string;
};

export type ForeignProceedingSubmitData = {
  id?: number;
  proceeding_type?: number;
  status?: number | string;
  name?: string;
  country_code?: string;
  code?: string;
  countries?: string[];
  identifier?: string;
  description_additional?: ForeignProceedingDescriptionAdditional[];
  bzp_identifier?: string;
  bzp_date?: string;
  contractor_name?: string;
  contractor_address?: string;
  contractor_email?: string;
  contractor_nip?: string;
  contractor_regon?: string;
  value?: number;
  links?: ForeignProceedingsLink[];
  requires_deposit?: boolean;
  deposit?: number;
  type?: number;
  mode?: number;
  social_clauses?: string;
  order_object?: string;
  email_contact_checked?: boolean;
  epd2_contact_checked?: boolean;
  post_contact_checked?: boolean;
  other_contact_checked?: boolean;
  other_contact_content?: string;
  announcement_link?: string;
  start_date_type?: number;
  start_date?: string | number;
  offers_send_date?: string;
  answer_place?: string;
  answer_way?: string;
  verification?: number;
  shopping_platform?: string;
  keywords_names?: string;
  keywords?: { id: number; name: string }[];
  files?: ForeignProceedingsFiles[];
  parts?: number | null;
  is_saved?: boolean;
  questions_date?: string;
  cpv_codes?: string[];
  codes?: ForeignProceedingsCodes[];
  cpv_codes_string?: string;
  from_bzp?: boolean;
  industry_type_id?: number;
  industry_sub_type_id?: number;
  industries?: number[];
  created_at?: string;
  updated_at?: string;
  order_nr?: string;
  slug_source?: string;
  source_name?: string;
  published_date?: string;
  deadline_date?: string;
  ordered_by?: string;
  ordered_by_info?: string;
  ref_nr?: string;
  href?: string;
  description?: string;
  procedure?: string;
  platform_mode?: string;
};

export default class ForeignProceedings {
  readonly id?: number;
  readonly proceeding_type?: number;
  readonly status?: number | string;
  readonly name?: string;
  readonly identifier?: string;
  readonly country_code?: string;
  readonly code?: string;
  readonly countries?: { code: string; country_name: string }[];
  readonly bzp_identifier?: string;
  readonly bzp_date?: string;
  readonly contractor_name?: string;
  readonly contractor_address?: string;
  readonly contractor_email?: string;
  readonly contractor_nip?: string;
  readonly description_additional?: ForeignProceedingDescriptionAdditional[];
  readonly contractor_regon?: string;
  readonly value?: number;
  readonly verification?: number;
  readonly links?: ForeignProceedingsLink[];
  readonly requires_deposit?: boolean;
  readonly deposit?: number;
  readonly type?: number;
  readonly mode?: number;
  readonly social_clauses?: string;
  readonly order_object?: string;
  readonly email_contact_checked?: boolean;
  readonly epd2_contact_checked?: boolean;
  readonly post_contact_checked?: boolean;
  readonly other_contact_checked?: boolean;
  readonly other_contact_content?: string;
  readonly announcement_link?: string;
  readonly start_date_type?: number;
  readonly start_date?: string | number;
  readonly offers_send_date?: string;
  readonly answer_place?: string;
  readonly answer_way?: string;
  readonly shopping_platform?: string;
  readonly keywords_names?: string;
  readonly keywords?: { id: number; name: string }[];
  readonly files?: ForeignProceedingsFiles[];
  readonly parts?: number | null;
  readonly is_saved?: boolean;
  readonly questions_date?: string;
  readonly cpv_codes?: string[];
  readonly cpv_codes_string?: string;
  readonly codes?: ForeignProceedingsCodes[];
  readonly from_bzp?: boolean;
  readonly industry_type_id?: number;
  readonly industry_sub_type_id?: number;
  readonly industries?: ForeignProceedingIndustry[];
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly order_nr?: string;
  readonly slug_source?: string;
  readonly source_name?: string;
  readonly published_date?: string;
  readonly deadline_date?: string;
  readonly ordered_by?: string;
  readonly ordered_by_info?: string;
  readonly ref_nr?: string;
  readonly href?: string;
  readonly description?: string;
  readonly procedure?: string;
  readonly platform_mode?: string;

  constructor(entity: ForeignProceedingsEntity) {
    this.id = entity.id;
    this.proceeding_type = entity.proceeding_type;
    this.status = entity.status;
    this.name = entity.name;
    this.identifier = entity.identifier;
    this.country_code = entity.country_code;
    this.code = entity.code;
    this.countries = entity.countries;
    this.bzp_identifier = entity.bzp_identifier;
    this.bzp_date = entity.bzp_date;
    this.contractor_name = entity.contractor_name;
    this.contractor_address = entity.contractor_address;
    this.contractor_email = entity.contractor_email;
    this.contractor_nip = entity.contractor_nip;
    this.description_additional = entity.description_additional;
    this.contractor_regon = entity.contractor_regon;
    this.value = entity.value;
    this.links = entity.links;
    this.requires_deposit = entity.requires_deposit;
    this.deposit = entity.deposit;
    this.type = entity.type;
    this.mode = entity.mode;
    this.social_clauses = entity.social_clauses;
    this.order_object = entity.order_object;
    this.email_contact_checked = entity.email_contact_checked;
    this.epd2_contact_checked = entity.epd2_contact_checked;
    this.post_contact_checked = entity.post_contact_checked;
    this.other_contact_checked = entity.other_contact_checked;
    this.other_contact_content = entity.other_contact_content;
    this.announcement_link = entity.announcement_link;
    this.start_date_type = entity.start_date_type;
    this.start_date = entity.start_date;
    this.offers_send_date = entity.offers_send_date;
    this.answer_place = entity.answer_place;
    this.answer_way = entity.answer_way;
    this.shopping_platform = entity.shopping_platform;
    this.keywords_names = entity.keywords_names;
    this.keywords = entity.keywords;
    this.files = entity.files;
    this.parts = entity.parts;
    this.is_saved = entity.is_saved;
    this.questions_date = entity.questions_date;
    this.cpv_codes = entity.cpv_codes;
    this.cpv_codes_string = entity.cpv_codes_string;
    this.codes = entity.codes;
    this.from_bzp = entity.from_bzp;
    this.industry_type_id = entity.industry_type_id;
    this.industry_sub_type_id = entity.industry_sub_type_id;
    this.industries = entity.industries;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.order_nr = entity.order_nr;
    this.slug_source = entity.slug_source;
    this.source_name = entity.source_name;
    this.published_date = entity.published_date;
    this.deadline_date = entity.deadline_date;
    this.ordered_by = entity.ordered_by;
    this.ordered_by_info = entity.ordered_by_info;
    this.ref_nr = entity.ref_nr;
    this.href = entity.href;
    this.description = entity.description;
    this.procedure = entity.procedure;
    this.platform_mode = entity.platform_mode;
  }

  static getFilterableAttributes(): string[] {
    return [
      'proceeding_type',
      'status',
      'name',
      'identifier',
      'bzp_identifier ',
      'country_code ',
      'code ',
      'contractor_name',
      'contractor_address',
      'contractor_email',
      'contractor_nip',
      'start_date_from',
      'start_date_to',
      'offers_send_date_from',
      'offers_send_date_to',
      'value_min',
      'value_max',
      'deposit_min',
      'deposit_max',
      'industries',
      'type',
      'mode',
      'has_social_clauses',
      'keywords',
      'questions_date_to',
      'questions_date_from',
      'slug_source',
      'source_name',
      'published_date',
      'published_date_from',
      'published_date_to',
      'deadline_date',
      'deadline_date_from',
      'deadline_date_to',
      'industry_sub_type_id',
      'industry_type_id',
      'ordered_by',
      'ordered_by_info',
      'ref_nr',
      'href',
      'description',
      'procedure',
      'platform_mode',
      'order_nr'
    ];
  }

  static getSortableAttributes(): string[] {
    return [
      'proceeding_type',
      'status',
      'name',
      'identifier',
      'contractor_name',
      'contractor_address',
      'contractor_regon',
      'contractor_email',
      'contractor_nip',
      'start_date',
      'offers_send_date',
      'value',
      'deposit',
      'industry_sub_type_id',
      'industry_type_id',
      'industries',
      'type',
      'mode',
      'order_nr',
      'slug_source',
      'published_date',
      'deadline_date',
      'ordered_by',
      'ordered_by_info',
      'ref_nr',
      'href',
      'description',
      'procedure',
      'platform_mode'
    ];
  }

  static getAgreementMode(type: number): AgreementMode {
    switch (type) {
      case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.SERVICES:
        return AGREEMENT_MODE_SERVICES;
      case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.CONSTRUCTIONS:
        return AGREEMENT_MODE_CONSTRUCTION_WORKS;
      case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.DELIVERY:
        return AGREEMENT_MODE_DELIVERIES;
      default: {
        return null;
      }
    }
  }
}

export const createForeignProceedings = (entity: ForeignProceedings): ForeignProceedings =>
  new ForeignProceedings(entity);

const romanNumber = (num: number) => {
  switch (num) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    default:
      return '';
  }
};

export const foreignProceedingsInitType = (type: number) => {
  switch (type) {
    case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.PLANNING:
      return FOREIGN_PROCEEDINGS_INIT_TYPES.PLANNING;
    case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.OPEN:
      return FOREIGN_PROCEEDINGS_INIT_TYPES.OPEN;
    default:
      return '';
  }
};

export const foreignProceedingsStatus = (status: number | string) => {
  switch (status) {
    case FOREIGN_PROCEEDINGS_STATUS_VALUES.PUBLISHED:
      return FOREIGN_PROCEEDINGS_STATUS.PUBLISHED;
    case FOREIGN_PROCEEDINGS_STATUS_VALUES.EXAMINING_OFFERS:
      return FOREIGN_PROCEEDINGS_STATUS.EXAMINING_OFFERS;
    case FOREIGN_PROCEEDINGS_STATUS_VALUES.CLOSED:
      return FOREIGN_PROCEEDINGS_STATUS.CLOSED;
    default:
      return '';
  }
};

export const foreignProceedingsType = (proceeding_type: number) => {
  switch (proceeding_type) {
    case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.SERVICES:
      return FOREIGN_PROCEEDINGS_INIT_TYPES.SERVICES;
    case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.CONSTRUCTIONS:
      return FOREIGN_PROCEEDINGS_INIT_TYPES.CONSTRUCTIONS;
    case FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.DELIVERY:
      return FOREIGN_PROCEEDINGS_INIT_TYPES.DELIVERY;
    default:
      return '';
  }
};

export const foreignProceedingsMode = (mode: number) => {
  switch (mode) {
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.OPEN_AUCTION:
      return FOREIGN_PROCEEDINGS_TYPES.OPEN_AUCTION;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.RESTRICTED_AUCTION:
      return FOREIGN_PROCEEDINGS_TYPES.RESTRICTED_AUCTION;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITH_ANNOUNCEMENT:
      return FOREIGN_PROCEEDINGS_TYPES.NEGOTIATION_WITH_ANNOUNCEMENT;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.DIALOGUE:
      return FOREIGN_PROCEEDINGS_TYPES.DIALOGUE;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.PARTNERSHIP:
      return FOREIGN_PROCEEDINGS_TYPES.PARTNERSHIP;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITHOUT_ANNOUNCEMENT:
      return FOREIGN_PROCEEDINGS_TYPES.NEGOTIATION_WITHOUT_ANNOUNCEMENT;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.FREE_HAND:
      return FOREIGN_PROCEEDINGS_TYPES.FREE_HAND;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.SOCIAL_SERVICES:
      return FOREIGN_PROCEEDINGS_TYPES.SOCIAL_SERVICES;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.BELOW_13K:
      return FOREIGN_PROCEEDINGS_TYPES.BELOW_13K;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.BASE_MODE:
      return FOREIGN_PROCEEDINGS_TYPES.BASE_MODE;
    case FOREIGN_PROCEEDINGS_TYPES_VALUES.PRINCIPLE_COMPETITIVENESS:
      return FOREIGN_PROCEEDINGS_TYPES.PRINCIPLE_COMPETITIVENESS;
    default:
      return '';
  }
};

export const foreignProceedingsStartDateType = (start_date_type: number) => {
  switch (start_date_type) {
    case START_DATE_TYPES_VALUES.QUARTER:
      return START_DATE_TYPES_VALUES.QUARTER;
    case START_DATE_TYPES_VALUES.MONTH:
      return START_DATE_TYPES.MONTH;
    case START_DATE_TYPES_VALUES.DATE:
      return START_DATE_TYPES.DATE;
    default:
      return '';
  }
};

export const foreignProceedingsStartDate = (start_date_type: number, start_date: string | number) => {
  switch (start_date_type) {
    case START_DATE_TYPES_VALUES.QUARTER:
      return `${START_DATE_TYPES.QUARTER} - ${romanNumber(start_date as number)}`;
    case START_DATE_TYPES_VALUES.MONTH:
      return `${START_DATE_TYPES.MONTH} - ${findMonthName(start_date as number)}`;
    case START_DATE_TYPES_VALUES.DATE:
      return formatDatetimeSeconds(start_date as string);
    default:
      return '';
  }
};

export const foreignProceedingInitTypeOptions = () => {
  return [
    { value: FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.PLANNING, label: FOREIGN_PROCEEDINGS_INIT_TYPES.PLANNING },
    { value: FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.OPEN, label: FOREIGN_PROCEEDINGS_INIT_TYPES.OPEN }
  ];
};

export const foreignProceedingStatusOptions = () => {
  return [
    { value: FOREIGN_PROCEEDINGS_STATUS_VALUES.PUBLISHED, label: FOREIGN_PROCEEDINGS_STATUS.PUBLISHED },
    { value: FOREIGN_PROCEEDINGS_STATUS_VALUES.EXAMINING_OFFERS, label: FOREIGN_PROCEEDINGS_STATUS.EXAMINING_OFFERS },
    { value: FOREIGN_PROCEEDINGS_STATUS_VALUES.CLOSED, label: FOREIGN_PROCEEDINGS_STATUS.CLOSED }
  ];
};

export const foreignProceedingVerificationOptions = () => {
  return [
    { value: FOREIGN_PROCEEDINGS_VERIFICATION_VALUES.OK, label: FOREIGN_PROCEEDINGS_VERIFICATION_LABELS.OK },
    { value: FOREIGN_PROCEEDINGS_VERIFICATION_VALUES.CHECK, label: FOREIGN_PROCEEDINGS_VERIFICATION_LABELS.CHECK },
    { value: FOREIGN_PROCEEDINGS_VERIFICATION_VALUES.ERROR, label: FOREIGN_PROCEEDINGS_VERIFICATION_LABELS.ERROR }
  ];
};

export const foreignProceedingModeOptions = () => {
  return [
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.OPEN_AUCTION, label: FOREIGN_PROCEEDINGS_TYPES.OPEN_AUCTION },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.RESTRICTED_AUCTION, label: FOREIGN_PROCEEDINGS_TYPES.RESTRICTED_AUCTION },
    {
      value: FOREIGN_PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITH_ANNOUNCEMENT,
      label: FOREIGN_PROCEEDINGS_TYPES.NEGOTIATION_WITH_ANNOUNCEMENT
    },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.DIALOGUE, label: FOREIGN_PROCEEDINGS_TYPES.DIALOGUE },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.PARTNERSHIP, label: FOREIGN_PROCEEDINGS_TYPES.PARTNERSHIP },
    {
      value: FOREIGN_PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITHOUT_ANNOUNCEMENT,
      label: FOREIGN_PROCEEDINGS_TYPES.NEGOTIATION_WITHOUT_ANNOUNCEMENT
    },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.FREE_HAND, label: FOREIGN_PROCEEDINGS_TYPES.FREE_HAND },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.SOCIAL_SERVICES, label: FOREIGN_PROCEEDINGS_TYPES.SOCIAL_SERVICES },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.BELOW_13K, label: FOREIGN_PROCEEDINGS_TYPES.BELOW_13K },
    { value: FOREIGN_PROCEEDINGS_TYPES_VALUES.BASE_MODE, label: FOREIGN_PROCEEDINGS_TYPES.BASE_MODE },
    {
      value: FOREIGN_PROCEEDINGS_TYPES_VALUES.PRINCIPLE_COMPETITIVENESS,
      label: FOREIGN_PROCEEDINGS_TYPES.PRINCIPLE_COMPETITIVENESS
    }
  ];
};

export const foreignProceedingStartDateTypeOptions = () => {
  return [
    { value: START_DATE_TYPES_VALUES.QUARTER, label: START_DATE_TYPES.QUARTER },
    { value: START_DATE_TYPES_VALUES.MONTH, label: START_DATE_TYPES.MONTH },
    {
      value: START_DATE_TYPES_VALUES.DATE,
      label: START_DATE_TYPES.DATE
    }
  ];
};

export const foreignProceedingStartDateQuarterOptions = () => {
  return [
    { value: 1, label: 'kwartał - I' },
    { value: 2, label: 'kwartał - II' },
    {
      value: 3,
      label: 'kwartał - III'
    },
    {
      value: 4,
      label: 'kwartał - IV'
    }
  ];
};

export const foreignProceedingsFileType = (fileType: number) => {
  switch (fileType) {
    case FOREIGN_PROCEEDINGS_FILES_VALUES.MAIN:
      return FOREIGN_PROCEEDINGS_FILES_TYPES.MAIN;
    case FOREIGN_PROCEEDINGS_FILES_VALUES.DESCRIPTION:
      return FOREIGN_PROCEEDINGS_FILES_TYPES.DESCRIPTION;
    case FOREIGN_PROCEEDINGS_FILES_VALUES.ORDINARY:
      return FOREIGN_PROCEEDINGS_FILES_TYPES.ORDINARY;
    default:
      return '';
  }
};

export const foreignProceedingsQuestionsDateOptions = (questionsDate?: string) => {
  if (!questionsDate) {
    return FOREIGN_PROCEEDINGS_QUESTIONS_DATE_MESSAGES.EMPTY;
  }

  const dateInfo = `do dnia ${formatDateIfExist(questionsDate, DATETIME_FORMAT_VALUE, DATETIME_SECONDS_FORMAT)}`;

  const isExpired = moment(questionsDate, DATETIME_FORMAT_VALUE).isBefore(getCurrentDateInFormat());
  if (isExpired) {
    return `${dateInfo}. ${FOREIGN_PROCEEDINGS_QUESTIONS_DATE_MESSAGES.EXPIRATION}`;
  }

  return dateInfo;
};

export const foreignProceedingsDepositRequiredOptions = () => {
  return [
    { value: 1, label: 'Tak' },
    { value: 0, label: 'Nie' }
  ];
};
