import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React, { useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Select from 'modules/Layout/component/Input/Select';
import { Props } from 'modules/ForeignProceedings/component/Fieldset/Main';
import {
  foreignProceedingStatusOptions,
  foreignProceedingVerificationOptions
} from '../../../../model/ForeignProceedings';
import { SelectOption } from '../../../../../Shared/type';
import {
  fetchDictionariesCountries,
  fetchForeignProceedingsIndustryTypesAdmin,
  fetchForeignProceedingsProceduresAdmin,
  fetchForeignProceedingsSourcesAdmin
} from '../../../../repository';

type SlugType = { slug: string; name: string };
type IndustryType = {
  id: number;
  name: string;
  subtypes?: IndustrySubType[];
};

type IndustrySubType = {
  id: number;
  name: string;
};

type DictionaryCountiesType = {
  [key: string]: string;
};

type ListType<T> = {
  all?: T[];
  selected?: T[];
};

const ForeignProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;
  const [sourcesList, setSourcesList] = useState<ListType<SlugType>>({
    all: [],
    selected: []
  });
  const [proceduresList, setProceduresList] = useState<ListType<SlugType>>({
    all: [],
    selected: []
  });
  const [industryTypesList, setIndustryTypesList] = useState<ListType<IndustryType>>({
    all: [],
    selected: []
  });
  const [dictionaryCountiesList, setDictionaryCountiesList] = useState<ListType<DictionaryCountiesType>>({
    all: [],
    selected: []
  });
  const [industrySubTypesList, setIndustrySubTypesList] = useState<ListType<IndustrySubType>>({
    all: [],
    selected: []
  });
  const [lastSelectedIndustry, setLastSelectedIndustry] = useState<IndustryType>(undefined);
  const [lastSelectedIndustrySubType, setLastSelectedIndustrySubType] = useState<IndustrySubType>(undefined);
  const [lastSelectedCode, setLastSelectedCode] = useState<string>('');
  const [needAddCode, setNeedAddCode] = useState<boolean>();
  const [lastCodeName, setLastCodeName] = useState<string>();

  const { slug_source = '', status, procedure, industries, countries, codes, verification } = values;

  useEffect(() => {
    const getValues = async () => {
      const responseSources = await fetchForeignProceedingsSourcesAdmin();
      const responseProcedures = await fetchForeignProceedingsProceduresAdmin();
      const responseIndustryTypes = await fetchForeignProceedingsIndustryTypesAdmin();
      const responseDictionaryCounties = await fetchDictionariesCountries();
      const newSources = responseSources.data;
      const newProcedures = responseProcedures.data;
      const newIndustryTypes = responseIndustryTypes.data;
      const newDictionaryCounties = responseDictionaryCounties.data.data;
      setSourcesList((prevState) => ({ ...prevState, all: newSources }));
      setProceduresList((prevState) => ({ ...prevState, all: newProcedures }));
      setIndustryTypesList((prevState) => ({ ...prevState, all: newIndustryTypes }));
      setDictionaryCountiesList((prevState) => ({
        ...prevState,
        all: newDictionaryCounties,
        selected: countries.map((country) => ({
          [country.code]: country.country_name
        }))
      }));
    };
    getValues();
  }, []);

  const sourcesOptions: SelectOption[] = sourcesList.all.map((sourceItem) => {
    return {
      value: sourceItem.slug,
      label: sourceItem.name
    };
  });
  const proceduresOptions: SelectOption[] = proceduresList.all.map((procedureItem) => {
    return {
      value: procedureItem.slug,
      label: procedureItem.name
    };
  });
  const industryTypesOptions: SelectOption[] = industryTypesList.all.map((industryTypeItem) => {
    return {
      value: industryTypeItem.id,
      label: industryTypeItem.name
    };
  });
  const industrySubTypesOptions: SelectOption[] = industrySubTypesList.all.map((industrySubTypeItem) => {
    return {
      value: industrySubTypeItem.id,
      label: industrySubTypeItem.name
    };
  });
  const dictionaryCountiesOptions: SelectOption[] = Object.entries(dictionaryCountiesList.all).map(([key, value]) => {
    return {
      value: key,
      label: String(value)
    };
  });
  const codesOptions: SelectOption[] = [
    {
      value: 'naics',
      label: 'naics'
    },
    {
      value: 'classification',
      label: 'classification'
    },
    {
      value: 'cpv',
      label: 'cpv'
    },
    {
      value: 'unspsc',
      label: 'unspsc'
    }
  ];

  const handleCountriesAdd = (country: { code: string; country_name: string }) => {
    setValues({
      ...values,
      countries: [...values.countries, { code: country.code, country_name: country.country_name }]
    });
  };

  const removeCountryFromSelected = (code: string) => {
    setValues({ ...values, countries: values.countries.filter((country) => country.code !== code) });
  };

  const onIndustryChange = (industry: IndustryType) => {
    setLastSelectedIndustry(industry);
    if (industry.subtypes.length > 0) {
      setIndustrySubTypesList({ all: industry.subtypes, selected: industry.subtypes });
    } else {
      setIndustrySubTypesList({ all: [], selected: [] });
      setValues({
        ...values,
        industries: [
          ...values.industries,
          {
            id: industry.id,
            industry_name: industry.name
          }
        ]
      });
    }
  };

  const onIndustrySubTypeChange = (subType: IndustrySubType) => {
    setLastSelectedIndustrySubType(subType);
    setValues({
      ...values,
      industries: [
        ...values.industries,
        {
          industry_type_id: lastSelectedIndustry.id,
          parent_type_name: lastSelectedIndustry.name,
          id: subType.id,
          industry_name: subType.name
        }
      ]
    });
  };

  const removeIndustryFromSelected = (industryId: number) => {
    setValues({ ...values, industries: values.industries.filter((i) => i.id !== industryId) });
    setLastSelectedIndustrySubType(undefined);
  };

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-slug_source">{suffixLabel('Rynek', required)}</Label>}
        <Select
          id="input-slug_source"
          searchable={false}
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, slug_source: String(newValue.value) })}
          value={sourcesOptions.find((val) => val.value === slug_source)}
          options={sourcesOptions}
        />
        {hasError(errors, 'slug_source') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-procedure">{suffixLabel('Procedura', required)}</Label>}
        <Select
          id="input-procedure"
          searchable={false}
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, procedure: String(newValue.value) })}
          value={proceduresOptions.find((val) => val.value === procedure)}
          options={proceduresOptions}
        />
        {hasError(errors, 'procedure') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-countries">{suffixLabel('Kraj wykonywania zlecenia')}</Label>}
        <Select
          id="input-countries"
          className="default-text-color"
          onChange={(newValue) => handleCountriesAdd({ code: String(newValue.value), country_name: newValue.label })}
          options={dictionaryCountiesOptions}
          required={false}
        />
        {hasError(errors, 'countries') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <div style={{ marginBottom: '20px', display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '6px' }}>
        {countries.map((country) => (
          <span
            style={{ marginRight: '12px', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}
            key={country.code}
          >
            {country.country_name}{' '}
            <button
              type="button"
              style={{
                marginLeft: '6px',
                padding: '0',
                backgroundColor: 'transparent',
                border: 'none'
              }}
              onClick={() => removeCountryFromSelected(country.code)}
            >
              <i className="fa cursor-pointer fa-times font-18" />
            </button>
          </span>
        ))}
      </div>
      <FormGroup>
        {!placeholder && <Label for="input-industry">{suffixLabel('Branże')}</Label>}
        <Select
          id="input-industry"
          searchable={false}
          required={false}
          className="default-text-color"
          onChange={(newValue) => {
            const selectedIndustry = industryTypesList.all.find((i) => i.id === newValue.value);
            onIndustryChange(selectedIndustry);
            setLastSelectedIndustry(selectedIndustry);
          }}
          value={industryTypesOptions.find((option) => option.value === lastSelectedIndustry?.id)}
          options={industryTypesOptions}
        />
        {hasError(errors, 'industries') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <div style={{ marginBottom: '20px' }}>
        {industries.map((i) => (
          <span style={{ marginRight: '12px', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }} key={i.id}>
            {i.industry_name} {i.parent_type_name && `- ${i.parent_type_name}`}
            <button
              type="button"
              style={{
                marginLeft: '6px',
                padding: '0',
                backgroundColor: 'transparent',
                border: 'none'
              }}
              onClick={() => removeIndustryFromSelected(i.id)}
            >
              <i className="fa cursor-pointer fa-times font-18" />
            </button>
          </span>
        ))}
      </div>
      <FormGroup>
        {!placeholder && <Label for="input-industry-sub-type">{suffixLabel('Branże podgrupa')}</Label>}
        <Select
          id="input-industry-sub-type"
          searchable={false}
          required={false}
          isDisabled={industrySubTypesOptions.length === 0}
          className="default-text-color"
          onChange={(newValue) => {
            const selectedSubType = industrySubTypesList.all.find((ist) => ist.id === newValue.value);
            onIndustrySubTypeChange(selectedSubType);
            setLastSelectedIndustrySubType(selectedSubType);
          }}
          value={industrySubTypesOptions.find((option) => option.value === lastSelectedIndustrySubType?.id)}
          options={industrySubTypesOptions}
        />
        {hasError(errors, 'industries') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-status">{suffixLabel('Status', required)}</Label>}
        <Select
          id="input-status"
          searchable={false}
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, status: newValue.value })}
          value={foreignProceedingStatusOptions().find((val) => val.value === status)}
          options={foreignProceedingStatusOptions()}
        />
        {hasError(errors, 'mode') && <FormFeedback>{getError(errors, 'mode')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-verification">{suffixLabel('Weryfikacja', required)}</Label>}
        <Select
          id="input-verification"
          searchable={false}
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, verification: newValue.value })}
          value={foreignProceedingVerificationOptions().find((val) => val.value === verification)}
          options={foreignProceedingVerificationOptions()}
        />
        {hasError(errors, 'verification') && <FormFeedback>{getError(errors, 'verification')}</FormFeedback>}
      </FormGroup>
      <div>
        {codes.map((c) => (
          <div key={c.id}>
            {c.code} - {c.type}
          </div>
        ))}
      </div>
      <Button onClick={() => setNeedAddCode(!needAddCode)}>Dodaj kod</Button>
      {needAddCode && (
        <>
          <FormGroup>
            {!placeholder && <Label for="input-codes">{suffixLabel('Typ kodu', required)}</Label>}
            <Select
              id="input-codes"
              searchable={false}
              className="default-text-color"
              onChange={(newValue) => setLastSelectedCode(String(newValue.value))}
              value={codesOptions.find((val) => val.value === lastSelectedCode)}
              options={codesOptions}
            />
            {hasError(errors, 'mode') && <FormFeedback>{getError(errors, 'mode')}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            {!placeholder && <Label for="input-codes">{suffixLabel('Nazwa kodu', required)}</Label>}
            <Input
              id="input-codes"
              searchable={false}
              className="default-text-color"
              onChange={(e) => setLastCodeName(e.target.value)}
              value={lastCodeName}
            />
            {hasError(errors, 'mode') && <FormFeedback>{getError(errors, 'mode')}</FormFeedback>}
          </FormGroup>
          <Button
            onClick={() => {
              setValues({ ...values, codes: [...values.codes, { type: lastSelectedCode, code: lastCodeName }] });
              setNeedAddCode(false);
            }}
          >
            Dodaj
          </Button>
        </>
      )}
    </>
  );
};

export default ForeignProceedingsFieldset;
