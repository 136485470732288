import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import Listing from 'modules/Layout/component/Listing';
import { breadcrumbRouteForeignProceedingsApiImports } from '../../breadcrumbs';
import ForeignProceedingsApiImportsTable from '../../container/ApiImportList/Table';
import ForeignProceedingsApiImportsFilter from '../../container/ApiImportList/Filter';
import { RootState } from '../../../../app/reducer';
import ForeignProceedingsApiImportsListingPagination from '../../container/ApiImportList/Pagination';
import ForeignProceedingsApiImportsPerPage from '../../container/ApiImportList/PerPage';
import { API_FOREIGN_PROCEEDINGS_API_IMPORT } from '../../repository';

const ForeignProceedingsApiImports = () => {
  const dispatch = useDispatch();

  const { list, fetching } = useSelector(
    (state: RootState) => state.foreignProceedingsApiImports.foreignProceedingsApiImportsList
  );

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Logi importów z API - lista',
      breadcrumb: breadcrumbRouteForeignProceedingsApiImports()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        <Listing
          filter={<ForeignProceedingsApiImportsFilter />}
          table={<ForeignProceedingsApiImportsTable foreignProceedingsApiImportsList={list} />}
          pagination={<ForeignProceedingsApiImportsListingPagination path={API_FOREIGN_PROCEEDINGS_API_IMPORT} />}
          perPage={<ForeignProceedingsApiImportsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default ForeignProceedingsApiImports;
