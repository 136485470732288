import React, { FC } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { ROUTE_USERS_EXECUTORS_EXTENDED } from 'modules/User/routes';
import { useSelector } from 'react-redux';
import ExecutorExtendedTable from 'modules/User/container/Table/ExecutorExtended';
import ExecutorExtendedFilter from 'modules/User/container/Filter/ExecutorExtended';
import ExecutorsExtendedPagination from 'modules/User/container/Pagination/ExecutorsExtended';
import ExecutorExtendedPerPage from 'modules/User/container/PerPage/ExecutorExtended';
import Executor from '../../../../Executor/model/Executor';

const ExecutorsExtendedList: FC = () => {
  const { fetching, meta } = useSelector((state: RootState) => state.user.executorsExtendedList);

  const searchProps = createSearchingProps('', Executor.getFilterableAttributes(), Executor.getSortableAttributes());

  return (
    <div className="row users-view">
      <div className="col-12">
        <Listing
          table={<ExecutorExtendedTable />}
          filter={<ExecutorExtendedFilter values={searchProps.filter} />}
          pagination={<ExecutorsExtendedPagination path={ROUTE_USERS_EXECUTORS_EXTENDED} />}
          perPage={<ExecutorExtendedPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default ExecutorsExtendedList;
